@charset 'utf-8';


@font-face {
	font-family: 'planet_estyleregular';
	src: url('../fonts/plane___-webfont.eot');
	src: url('../fonts/plane___-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/plane___-webfont.woff') format('woff'),
	url('../fonts/plane___-webfont.ttf') format('truetype'),
	url('../fonts/plane___-webfont.svg#planet_estyleregular') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'botonregular';
	src: url('../fonts/unicode.boton-webfont.woff2') format('woff2'),
	url("../fonts/unicode.boton-webfont.ttf") format("truetype"),
	url('../fonts/unicode.boton-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "foundation-icons";
	src: url("../fonts/foundation-icons.eot");
	src: url("../fonts/foundation-icons.eot?#iefix") format("embedded-opentype"),
	url("../fonts/foundation-icons.woff") format("woff"),
	url("../fonts/foundation-icons.ttf") format("truetype"),
	url("../fonts/foundation-icons.svg#fontcustom") format("svg");
	font-weight: normal;
	font-style: normal;
}

$boton: 'botonregular';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-switch;
@include foundation-table;
// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;



.palmedia {
	font-family: "planet_estyleregular";
	font-size: 110%;
	color: $white;
	&:hover {
		color: $medium-gray;
	}
}

.home-banner {
	background-image: url(../img/home-dorpshuis-gelster.jpg);
	background-repeat: no-repeat;
	background-size:cover;
	background-position: center;
	display: block;
	height: 429px;
	margin-bottom: 16px;
}
.header-logo {
	margin: 10px;
	img {
		max-height: 100px;
	}
}
.header-right {
	margin-top: 40px;
}

.off-canvas {
	ul.menu {
		li {
			ul.submenu {
				list-style: none;
				li {
					a {
						&:before {
							font-family: "foundation-icons";
							content: "\f10b"; 
							display: inline-block;
							margin-left: -1.3em; /* same as padding-left set on li */
							width: 1.3em; /* same as padding-left set on li */
						}
					}
				}
			}
		}
	}
}

.top-bar {
	padding: 0;
	ul {
		margin: 10px;
	}
	.hamburger-menu {
		margin: 10px;
		@include hamburger($white, $light-gray, 30px, 26px, 5px, 3);
	}
	ul.dropdown {
		background: transparent;
		display: flex;
		justify-content: center;
	}
	ul.menu {
		a {
			color: $white;
			&:hover {
				color: $light-gray;
			}
			&:active {
				color: $light-gray;
			}
		}
		li.is-active > a {
			color: $light-gray;

		}
	}
}

.news-flash {
	position: relative;
	padding-left: 60px;
	&:before {
 		color: $primary-color;
		font-family: "foundation-icons";
 		font-size: rem-calc(60);
 		position: absolute;
 		line-height: 60px;
		margin-left: -60px;
		width: 60px;
 	}
 	h2 {
 		font-size: rem-calc(27);
 		text-transform: uppercase;
 		color: $primary-color;
 	}
 	p {
 		font-size: rem-calc(18);
 		color: $primary-color;
		a {
			text-decoration: underline;
			&:hover {
				color: $pm-green;
			}
		}
 	}
}
.card-home {
	position: relative;
	font-family: $boton;
	//border-radius: 0;
	.card-section {
		position: absolute;
		bottom: 0;
		width: 100%;
		background-color: rgba($primary-color, 0.7);
		&.green {
			background-color: rgba($pm-green, 0.7);
		}
		&.blue {
			background-color: rgba($pm-blue, 0.7);
		}
		&.purple {
			background-color: rgba($pm-purple, 0.7);
		}
		&.orange {
			background-color: rgba($pm-orange, 0.7);
		}
		&.red {
			background-color: rgba($pm-red, 0.7);
		}
		&.lightgreen {
			background-color: rgba($pm-lightgreen, 0.7);
		}
		.title {
			font-size: rem-calc(27);
		}
		.button {
			margin: 0;
		}
	}
}
@media screen and #{breakpoint(medium)} {
	.card-home {
		border-radius: $global-radius;
	}
}

footer {
	background-color: $primary-color;
	padding-top: 20px;
	height: 75px;
	color: $white;
	font-size: rem-calc(14);
	text-align: center;
	align-content: center;
	a {
		color: $white;
		&:hover {
			color: $light-gray;
		}
	}
}